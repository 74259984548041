import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import subtitles from "./subtitles.json";

const Subtitles = () => {
  const [subtitle, setSubtitle] = useState(subtitles[0]);
  const [subAnimation, setSubAnimation] = useState(true);

  const handleAnimation = () => {
    setTimeout(() => {
      setSubAnimation(false);
    }, 4000);
  };

  const loopSubtitles = () => {
    setTimeout(() => {
      let current = subtitles.indexOf(subtitle);
      setSubAnimation(true);
      if (current === subtitles.length - 1) {
        setSubtitle(subtitles[0]);
      } else {
        setSubtitle(subtitles[current + 1]);
      }
    }, 5000);
  };

  useEffect(() => {
    handleAnimation();
    loopSubtitles();
    //eslint-disable-next-line
  }, [subtitle]);

  return (
    <Animated
      animationIn="fadeInUp"
      animationOut="fadeOutUp"
      isVisible={subAnimation}
    >
      {subtitle}
    </Animated>
  );
};

export default Subtitles;
