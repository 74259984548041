import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "home.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      portfolio: file(relativePath: { eq: "portfolio.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedin: file(relativePath: { eq: "linkedin.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      blog: file(relativePath: { eq: "blog.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      contact: file(relativePath: { eq: "contact.png" }) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      photo: file(relativePath: { eq: "stefano.jpg" }) {
        childImageSharp {
          fixed(width: 270, height: 270, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      smallerphoto: file(relativePath: { eq: "stefano.jpg" }) {
        childImageSharp {
          fixed(width: 120, height: 120, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      penn: file(relativePath: { eq: "penn.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hult: file(relativePath: { eq: "hult.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ateneo: file(relativePath: { eq: "ateneo-impresa.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      federico: file(relativePath: { eq: "federico.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      html: file(relativePath: { eq: "html-js-css.png" }) {
        childImageSharp {
          fixed(width: 150, height: 87) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      flutter: file(relativePath: { eq: "flutter.png" }) {
        childImageSharp {
          fixed(width: 57, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      react: file(relativePath: { eq: "react.png" }) {
        childImageSharp {
          fixed(width: 80, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      node: file(relativePath: { eq: "node.png" }) {
        childImageSharp {
          fixed(width: 90, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lean: file(relativePath: { eq: "lean-startup.png" }) {
        childImageSharp {
          fixed(width: 75, height: 68) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      scrum: file(relativePath: { eq: "scrum.png" }) {
        childImageSharp {
          fixed(width: 75, height: 66) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iot: file(relativePath: { eq: "iot-slash.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iot: file(relativePath: { eq: "iot-slash.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      comcast: file(relativePath: { eq: "comcast.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mediamelon: file(relativePath: { eq: "mediamelon.png" }) {
        childImageSharp {
          fixed(width: 75, height: 66) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vfgroup: file(relativePath: { eq: "vodafone-group.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vfitaly: file(relativePath: { eq: "vodafone-italy.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      lbi: file(relativePath: { eq: "lbi-italy.png" }) {
        childImageSharp {
          fixed(width: 75, height: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      deerfieldHP: file(relativePath: { eq: "deerfield.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      deerfieldPortfolio: file(relativePath: { eq: "deerfield.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thereyetHP: file(relativePath: { eq: "thereyet.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thereyetPortfolio: file(relativePath: { eq: "thereyet.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      airluggageHP: file(relativePath: { eq: "airluggage.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      airluggagePortfolio: file(relativePath: { eq: "airluggage.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      wyzeHP: file(relativePath: { eq: "wyze-accessories.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      wyzePortfolio: file(relativePath: { eq: "wyze-accessories.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      breadcrumbsHP: file(relativePath: { eq: "breadcrumbs.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      breadcrumbsPortfolio: file(relativePath: { eq: "breadcrumbs.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      movieHP: file(relativePath: { eq: "movie-picker.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      moviePortfolio: file(relativePath: { eq: "movie-picker.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      xfinityHP: file(relativePath: { eq: "xfinity.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      xfinityPortfolio: file(relativePath: { eq: "xfinity.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      homecloudHP: file(relativePath: { eq: "homecloud.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      homecloudPortfolio: file(relativePath: { eq: "homecloud.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tvHP: file(relativePath: { eq: "tv-connect.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      tvPortfolio: file(relativePath: { eq: "tv-connect.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      la3HP: file(relativePath: { eq: "la3.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      la3Portfolio: file(relativePath: { eq: "la3.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      blockbusterHP: file(relativePath: { eq: "blockbuster.jpg" }) {
        childImageSharp {
          fixed(width: 540, height: 370, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      blockbusterPortfolio: file(relativePath: { eq: "blockbuster.jpg" }) {
        childImageSharp {
          fixed(width: 330, height: 226, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedinButton: file(relativePath: { eq: "linkedin-button.png" }) {
        childImageSharp {
          fixed(width: 150, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      license: file(relativePath: { eq: "cc-by-sa.png" }) {
        childImageSharp {
          fixed(width: 80, height: 15) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  switch (name) {
    case "home":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.home.childImageSharp.fixed}
        />
      );
    case "portfolio":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.portfolio.childImageSharp.fixed}
        />
      );
    case "linkedin":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.linkedin.childImageSharp.fixed}
        />
      );
    case "blog":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.blog.childImageSharp.fixed}
        />
      );
    case "twitter":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.twitter.childImageSharp.fixed}
        />
      );
    case "contact":
      return (
        <Img
          className="menu-icon"
          style={{ position: "absolute" }}
          fixed={data.contact.childImageSharp.fixed}
        />
      );
    case "photo":
      return <Img fixed={data.photo.childImageSharp.fixed} />;
    case "smallerphoto":
      return <Img fixed={data.smallerphoto.childImageSharp.fixed} />;
    case "penn":
      return (
        <Img
          className="logo"
          title="Full Stack Web Development Bootcamp @ Penn - University of Pennsylvania"
          fixed={data.penn.childImageSharp.fixed}
        />
      );
    case "hult":
      return (
        <Img
          className="logo"
          title="MBA w/ Honors @ Hult International Business School"
          fixed={data.hult.childImageSharp.fixed}
        />
      );
    case "ateneo":
      return (
        <Img
          className="logo"
          title="Master in New Media Marketing @ Ateneo Impresa"
          fixed={data.ateneo.childImageSharp.fixed}
        />
      );
    case "federico":
      return (
        <Img
          className="logo"
          title="BA + MA in Law @ Universita' Federico II"
          fixed={data.federico.childImageSharp.fixed}
        />
      );
    case "html":
      return (
        <Img
          className="logo"
          id="html-img"
          style={{ position: "absolute", top: "15px", left: "50px" }}
          title="HTML / Javascript / CSS"
          fixed={data.html.childImageSharp.fixed}
        />
      );
    case "flutter":
      return (
        <Img
          className="logo"
          style={{ position: "absolute", top: "120px", right: "45px" }}
          title="Flutter"
          fixed={data.flutter.childImageSharp.fixed}
        />
      );
    case "react":
      return (
        <Img
          className="logo"
          style={{ position: "absolute", top: "120px", left: "30px" }}
          title="React / React Native"
          fixed={data.react.childImageSharp.fixed}
        />
      );
    case "node":
      return (
        <Img
          className="logo"
          style={{ position: "absolute", bottom: "85px", left: "75px" }}
          title="Node.js"
          fixed={data.node.childImageSharp.fixed}
        />
      );
    case "scrum":
      return (
        <Img
          className="logo"
          style={{ position: "absolute", bottom: "15px", left: "25px" }}
          title="Scrum Product Owner"
          fixed={data.scrum.childImageSharp.fixed}
        />
      );
    case "lean":
      return (
        <Img
          className="logo"
          style={{ position: "absolute", bottom: "15px", right: "25px" }}
          title="Lean Startup"
          fixed={data.lean.childImageSharp.fixed}
        />
      );
    case "iot":
      return (
        <Img
          className="logo"
          fixed={data.iot.childImageSharp.fixed}
          title="Founder & CEO @ IoT Slash"
        />
      );
    case "comcast":
      return (
        <Img
          className="logo"
          fixed={data.comcast.childImageSharp.fixed}
          title="Director of Product Management - Xfinity Home / IoT @ Comcast"
        />
      );
    case "mediamelon":
      return (
        <Img
          className="logo"
          fixed={data.mediamelon.childImageSharp.fixed}
          title="Lead Product Manager - Video Streaming @ Mediamelon"
        />
      );
    case "vfgroup":
      return (
        <Img
          className="logo"
          fixed={data.vfgroup.childImageSharp.fixed}
          title="Global Product Manager - Connected Home @ Vodafone Group"
        />
      );
    case "vfitaly":
      return (
        <Img
          className="logo"
          fixed={data.vfitaly.childImageSharp.fixed}
          title="Senior Product Manager - Broadband & Video @ Vodafone Italy"
        />
      );
    case "lbi":
      return (
        <Img
          className="logo"
          fixed={data.lbi.childImageSharp.fixed}
          title="Client / Project Manager - Web & IPTV @ LBi Italy"
        />
      );
    case "deerfieldHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.deerfieldHP.childImageSharp.fixed}
        />
      );
    case "deerfieldPortfolio":
      return <Img fixed={data.deerfieldPortfolio.childImageSharp.fixed} />;
    case "thereyetHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.thereyetHP.childImageSharp.fixed}
        />
      );
    case "thereyetPortfolio":
      return <Img fixed={data.thereyetPortfolio.childImageSharp.fixed} />;
    case "airluggageHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.airluggageHP.childImageSharp.fixed}
        />
      );
    case "airluggagePortfolio":
      return <Img fixed={data.airluggagePortfolio.childImageSharp.fixed} />;
    case "wyzeHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.wyzeHP.childImageSharp.fixed}
        />
      );
    case "wyzePortfolio":
      return <Img fixed={data.wyzePortfolio.childImageSharp.fixed} />;
    case "breadcrumbsHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.breadcrumbsHP.childImageSharp.fixed}
        />
      );
    case "breadcrumbsPortfolio":
      return <Img fixed={data.breadcrumbsPortfolio.childImageSharp.fixed} />;
    case "movieHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.movieHP.childImageSharp.fixed}
        />
      );
    case "moviePortfolio":
      return <Img fixed={data.moviePortfolio.childImageSharp.fixed} />;
    case "xfinityHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.xfinityHP.childImageSharp.fixed}
        />
      );
    case "xfinityPortfolio":
      return <Img fixed={data.xfinityPortfolio.childImageSharp.fixed} />;
    case "homecloudHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.homecloudHP.childImageSharp.fixed}
        />
      );
    case "homecloudPortfolio":
      return <Img fixed={data.homecloudPortfolio.childImageSharp.fixed} />;
    case "tvHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.tvHP.childImageSharp.fixed}
        />
      );
    case "tvPortfolio":
      return <Img fixed={data.tvPortfolio.childImageSharp.fixed} />;
    case "la3HP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.la3HP.childImageSharp.fixed}
        />
      );
    case "la3Portfolio":
      return <Img fixed={data.la3Portfolio.childImageSharp.fixed} />;
    case "blockbusterHP":
      return (
        <Img
          className="portfolio-img"
          fixed={data.blockbusterHP.childImageSharp.fixed}
        />
      );
    case "blockbusterPortfolio":
      return <Img fixed={data.blockbusterPortfolio.childImageSharp.fixed} />;
    case "linkedin-button":
      return <Img fixed={data.linkedinButton.childImageSharp.fixed} />;
    case "license":
      return (
        <Img
          className="license-img"
          fixed={data.license.childImageSharp.fixed}
          title="All my Articles can be shared under a Creative Commons Attribution-ShareAlike 4.0 International License"
        />
      );
    default:
      console.log("No valid image name provided");
      return null;
  }
};

export default Image;
